<template>
	<div v-loading.lock="fullscreenLoading">
        <div class="search-container">
            <div
                style="margin-left: auto; display: flex; justify-content: space-around"
            >
                <div class="search-container-fn-input">
                    <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-document-add"
                        style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                                background-color: #2362FB; border-color: #2362FB"
                        @click="handleAdd()"
                        >新增分类
                    </el-button>
                </div>

                <div class="search-container-fn-input">
                    <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                                background-color: #2362FB; border-color: #2362FB"
                        @click="handleAdd('update')"
                        >修改分类
                    </el-button>
                </div>

                <div class="search-container-fn-input">
                    <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;"
                        @click="handleDel()"
                        >删除分类
                    </el-button>
                </div>

            </div>
        </div>
		<el-table
            ref="multipleTable"
            :header-cell-style="{ 'text-align': 'center' }"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
            style="width: 100%">
            <el-table-column
            align="center"
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
            prop="id"
            align="center"
            label="ID">
            </el-table-column>
            <el-table-column
            prop="name"
            align="center"
            label="名称">
            </el-table-column>
            <el-table-column
            prop="sort"
            align="center"
            label="排序">
            </el-table-column>
            <el-table-column
            align="center"
                prop="created_at"
                label="创建时间">
            </el-table-column>
        </el-table>

        <!-- 新增分类 -->
        <el-dialog
            :title="title"
            :visible.sync="centerDialogVisible"
            width="30%"
            center>
            <el-form :model="form" :rule="rules">
                <el-form-item label="分类名称" prop="name" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth">
                    <el-input v-model="form.sort" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handlSaveCate">确 定</el-button>
            </span>
            </el-dialog>
	</div>
</template>

<script>
import {
    articleCateList,
    saveCate,
    delCate
} from "@/api/article/index"

export default {
  name: "articleCate",
  created () {
    this.getList()
  },
  components: {  },
  computed: {
   
  },
  data () {
    return {
        tableData:[],
        fullscreenLoading:true,
        centerDialogVisible:false,
        form:{
            name:"",
            sort:0
        },
        rules:[],
        formLabelWidth: '120px',
        title:"新增分类",
        multipleSelection:[],
        multipleSelectionItem:[]
    }
  },
  methods: {
    getList(){
        this.fullscreenLoading = true
        articleCateList().then(res=>{
            this.fullscreenLoading = false
            if (res.code == 200){
                this.tableData = res.data
            }
        })  
    },
    handleAdd(type = "add"){    
        if (type == "add"){
            this.form = {
                name:"",
                sort:0  
            }
            this.title = "新增分类"
        }else{
            let res = this._checkSelection()
            if (!res){
                return 
            }
            this.title = "修改分类"
            this.form = this.multipleSelectionItem[0]        
        }
         
        this.centerDialogVisible = true
    },
    handleDel(){
        let res = this._checkSelection()
        if (!res){
            return 
        }
        this.$confirm('确认删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.fullscreenLoading = true
            delCate({id:this.multipleSelection[0]}).then(res => {
                this.fullscreenLoading = false
                if(res.code == 200){
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }
            })
        }).catch(() => {
            this.fullscreenLoading = false
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    },
    _checkSelection(){
        if (this.multipleSelectionItem.length > 1){
            this.$message.error("只能选择一条记录操作")
            return false
        }
        if (this.multipleSelectionItem.length == 0){
            this.$message.error("请选择")
            return false
        }
        return true
    },

    handlSaveCate(){
        this.fullscreenLoading = true
        saveCate(this.form).then(res=>{
            this.fullscreenLoading = false
            if (res.code == 200){
                this.getList()
                this.$message.success("操作成功")
                this.centerDialogVisible = false
            }else{
                this.$message.error(res.msg)
            }
        })       
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionItem = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionItem.push(item);
      });
    },
  }
}
</script>

<style lang="scss">
	.avatar {
		height: 200px;
		width: auto;
	}
	
	.el-input.is-disabled {
		.el-input__inner {
			border: none;
			background: transparent;
			color: #333;
		}
		
		.el-icon-arrow-down {
			display: none;
		}
		
		.el-input__suffix {
			display: none;
		}
	}
	
	.preContent {
		margin: 0;
		overflow: auto;
		white-space: pre-wrap;
		border: 1px solid #ececec;
		
		p {
			margin: 0;
			padding: 0;
		}
	}
	
	.imgUpload {
		background: url("../../assets/icon_upload.png") no-repeat;
		background-position: center;
		width: 160px;
		height: 110px;
		background-size: cover;
		
	}
	
	.width278 {
		width: 578px;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	.el-form-item {
		margin-bottom: 30px;
	}
	
	.bbox {
		.el-form-item {
			display: flex;
			align-items: center;
			
			.el-form-item__content {
				margin-left: 0 !important;
			}
		}
	}
</style>
